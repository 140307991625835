import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import '../styles/ContactUs.css'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';



const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'contactus', content: 'CONTACT US', active: true },
]
function ContactUs() {

    return (

        <div>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>
            <div className="contact-header">Contact Us</div>
            <div className="contact-columns" style={{ paddingLeft: 'auto', paddingRight: 'auto' }}>
                <Container fluid className="contacts-container">
                    <Row className="align-items-first align-items-start">
                        <Col xs={12} md={6} lg={3}>
                            <div className="contact-info">

                                <div className="department-header">
                                    <h4 role='heading'><b>CORPORATE</b></h4>
                                </div>
                                <div className="sub-department">
                                    <h5 role='heading'><b>Investor Relations, Mergers and Acquisitions</b></h5>
                                </div>
                                <div className="contact">
                                    <a href={"mailto:investors@ttgi.com"}>investors@ttgi.com</a>
                                    <h8></h8>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Human Resources</b></h5>
                                </div>
                                <div className="contact">
                                    <a href={"mailto:hr@ttgi.com"}>hr@ttgi.com</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Accounting</b></h5>
                                </div>
                                <div className="contact">
                                    <a href={"mailto:billing@ttgi.com"}>billing@ttgi.com</a>
                                    < br />
                                    <a href={"mailto:pod@ttgi.com"}>pod@ttgi.com</a>
                                    < br />
                                    <a href={"mailto:payables@ttgi.com"}>payables@ttgi.com</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Driver Recruitment</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>Canada:<a href="tel:(905) 266 3303"> (905) 266 3303</a></h8>
                                    <br/>
                                    <h8>USA:<a href="tel:(833) 815 8284"> (833) 815 8284</a></h8>
                                    < br />
                                    <a href={"mailto:driverjobs@ttgi.com"}>driverjobs@ttgi.com</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Maintenance Team</b></h5>
                                </div>
                                <div className="contact">
                                    <a href="tel:(833) 887 4349">(833) 887 4349 </a>
                                </div>
                            </div>

                        </Col>

                        <Col xs={12} md={6} lg={3}>
                            <div className="contact-info">
                                <div className="department-header">
                                    <h4 role='heading'><b>SALES INQUIRIES</b></h4>
                                </div>
                                <div className="sub-department">
                                    <h5 role='heading'><b>Trucking / Logistics</b></h5>
                                </div>
                                <div className="contact">
                                <a href="tel:(905) 266 3010">(905) 266 3010 Ext. 3057</a> 
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Warehousing</b></h5>
                                </div>
                                <div className="contact">
                                    <a href="tel:(905) 266 3010">(905) 266 3010 </a> 
                                    < br />
                                    <a href={"mailto:warehouse@ttgi.com"}>warehouse@ttgi.com</a>
                                </div>    
                                < br />                            
                                <div className="sub-department">
                                    <h5 role='heading'><b>Quote Inquiries</b></h5>
                                </div>
                                <div className="contact">
                                <Link to='/requestQuote'>Request Quote</Link>
                                </div>
                                < br />
                                < br />
                                < br />
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="contact-info">
                                <div className="department-header" >

                                    <h4 role='heading'><b>LOGISTICS</b></h4>
                                </div>
                                <div className="sub-department">
                                    <h5 role='heading'><b>General</b></h5>
                                </div>
                                <div className="contact">
                                    <a href="tel:(905) 851 1688">(905) 851 1688</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>American Operations Inquiries</b></h5>
                                </div>
                                <div className="contact">
                                    <a href={"mailto:TALI.ops@ttgi.com"}>TALI.ops@ttgi.com</a>
                                </div>
                                < br />.
                            </div>
                        </Col>

                    </Row>
                    <Row className="align-items-first align-items-start" >
                        <Col xs={12} md={6} lg={3}>
                            <div className="contact-info">
                                <div className="department-header" style={{ marginTop: '50px' }}>

                                    <h4 role='heading'><b>AFTER-HOURS - CANADIAN</b></h4>
                                </div>
                                <div className="sub-department">
                                    <h5 role='heading'><b>Trucking Dispatch</b></h5>
                                </div>
                                <div className="contact">
                                    <h8><b>All Terminals: </b> <a href="tel:(905) 266 3010">(905) 266 3010 Ext. 1, 3</a></h8>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Logistics Dispatch</b></h5>
                                </div>
                                <div className="contact">
                                    <h8><b>General :</b>  <a href="tel:(289) 906 0429">(289) 906 0429</a></h8>
                                    < br />
                                    <a href={"mailto:tliafterhours@ttgi.com"}>tliafterhours@ttgi.com</a>
                                </div>
                                < br />


                                
                                <div className="department-header" style={{ marginTop: '50px' }}>

                                    <h4 role='heading'><b>Safety</b></h4>
                                </div>
                                <div className="sub-department">
                                    <h5 role='heading'><b>Canadian </b></h5>
                                </div>
                                <div className="contact">
                                    <h8><a href="tel:(905) 266 3010">(905) 266 3010 Ext. 5</a></h8>
                                    <br/>
                                    <h8><a href="mailto:safety@ttgi.com">safety@ttgi.com</a></h8>
                                </div>
                                <br/>
                                <div className="sub-department">
                                    <h5 role='heading'><b>American </b></h5>
                                </div>
                                <div className="contact">
                                    <h8><a href="tel:(770) 532 0057">(770) 532 0057 Ext. 5</a></h8>
                                    <br/>
                                    <h8><a href="mailto:tatisafety@ttgi.com">tatisafety@ttgi.com</a></h8>
                                </div>

                                < br />
                                < br />
                            </div>



                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="contact-info" style={{ marginTop: '50px' }}>

                                <div className="department-header">
                                    <h4 role='heading'><b>AFTER-HOURS - AMERICAN</b></h4>
                                </div>

                                <div className="sub-department">
                                    <h5 role='heading'><b>Logistics Emergency</b></h5>
                                </div>
                                <div className="contact">


                                    <h8><b>Atlanta, GA: </b><a href="tel:(470) 372 5060">(470) 372 5060</a></h8>
                                    < br />
                                    <a href={"mailto:atlanta.ops@ttgi.com"}>atlanta.ops@ttgi.com</a>
                                    < br />
                                    < br />
                                    <h8><b>Charlotte, NC: </b> <a href="tel:(704) 703 8002">(704) 703 8002</a></h8>
                                    < br />
                                    <a href={"mailto:charlotte.ops@ttgi.com"}>charlotte.ops@ttgi.com</a>
                                    < br />
                                    < br />
                                    <h8><b>Chicago, IL: </b><a href="tel:(312) 547 4900">(312) 547 4900</a></h8>
                                    < br />
                                    <a href={"mailto:chicago.ops@ttgi.com"}>chicago.ops@ttgi.com</a><h8></h8>
                                    <br />
                                    < br />
                                    <h8><b>Denver, CO: </b>  <a href="tel:(303) 749 8501">(303) 749 8501</a></h8>
                                    < br />
                                    <a href={"mailto:denver.ops@ttgi.com"}>denver.ops@ttgi.com</a>
                                    < br />< br />
                                    <h8><b>Fayetteville, AR: </b><a href="tel:(479) 332 3007">(479) 332 3007</a></h8>
                                    < br />
                                    <a href={"mailto:fayetteville.ops@ttgi.com"}>fayetteville.ops@ttgi.com</a>
                                    < br />< br />
                                    <h8><b>Jacksonville, FL: </b><a href="tel:(904) 865 1415">(904) 865 1415</a></h8>
                                    < br />
                                    <a href={"mailto:jacksonville.ops@ttgi.com"}>jacksonville.ops@ttgi.com</a>
                                    < br />< br />
                                    <h8><b>Nashville, TN: </b><a href="tel:(615) 514 1061">(615) 514 1061</a></h8>
                                    < br />
                                    <a href={"mailto:nashville.ops@ttgi.com"}>nashville.ops@ttgi.com</a>
                                    < br />< br />
                                    <h8><b>Oakwood, GA: </b><a href="tel:(770) 532 0057">(770) 532 0057</a></h8>
                                    < br />
                                    <a href={"mailto:oakwood.ops@ttgi.com"}>oakwood.ops@ttgi.com</a>


                                </div>
                                < br />
                                < br />
                            </div>
                        </Col>

                        <Col xs={12} md={6} lg={3}>
                            <div className="contact-info">
                                <div className="department-header" style={{ marginTop: '50px' }}>

                                    <h4 role='heading'><b>AMERICAN TERMINALS</b></h4>
                                </div>
                                <div className="sub-department">
                                    <h5 role='heading'><b>Atlanta, Georgia </b></h5>
                                </div>
                                <div className="contact">
                                    <h8>2 Ravinia Dr., Suite 910, 30346</h8>
                                    < br />
                                    <a href="tel:(470) 372 5060">(470) 372 5060</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Charlotte, North Carolina</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>5950 Fairview Rd., Suite 502, 28210</h8>
                                    < br />
                                    <a href="tel:(704) 703 8002">(704) 703 8002</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Chicago, Illinois</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>17 N State St., Suite 990, 60602</h8>
                                    < br />
                                    <a href="tel:(312) 547 4900">(312) 547 4900</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Denver, Colorado</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>4600 S Ulster St., Suite 1022, 80237</h8>
                                    < br />
                                    <a href="tel:(303) 749 8501">(303) 749 8501</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Fayetteville, Arkansas</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>2828 N Mansfield St., Suite 101, 72704</h8>
                                    < br />
                                    <a href="tel:(479) 332 3007">(479) 332 3007</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Jacksonville, Florida</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>4887 Belfort Road, Suite 207, 32256</h8>
                                    < br />
                                    <a href="tel:(904) 865 1415">(904) 865 1415</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Nashville, Tennessee</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>3310 West End Ave, Suite 410, 37203</h8>
                                    < br />
                                    <a href="tel:(615) 514 1061">(615) 514 1061</a>
                                </div>
                                < br />
                                <div className="sub-department">
                                    <h5 role='heading'><b>Oakwood, Georgia</b></h5>
                                </div>
                                <div className="contact">
                                    <h8>4125 W White Rd, 30566</h8>
                                    < br />
                                    <a href="tel:(770) 532 0057">(770) 532 0057</a>
                                </div>
                                < br />

                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="contact-info" style={{ marginTop: '50px' }}>
                                <div className="department-header">
                                    <h4><b>CANADIAN TERMINALS</b></h4>
                                </div>

                                <div className="sub-department">
                                    <h5><b>Bolton, Ontario (head office)</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p  style={{ marginBottom: '0'}}>32 Simpson Road, L7E 1G9</p>
                                        <a href="tel:(905) 266 3010">(905) 266 3010</a>
                                    </div>
                                    <br></br>
                                </div>

                                <div className="sub-department">
                                    <h5><b>Belleville, Ontario</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p  style={{ marginBottom: '0'}}>107 Bellevue Drive, K8N 4Z5</p>
                                        <a href="tel:(613) 961 4700">(613) 961 4700</a>
                                    </div>
                                    <br></br>
                                </div>

                                <div className="sub-department">
                                    <h5><b>Bracebridge, Ontario</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p  style={{ marginBottom: '0'}}>456 Ecclestone Drive, P1L 1R1</p>
                                        <a href="tel:(705) 645 4481">(705) 645 4481</a>
                                    </div>
                                    <br></br>
                                </div>

                                <div className="sub-department">
                                    <h5><b>Brantford, Ontario</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p  style={{ marginBottom: '0'}}>31 Ewart Ave, N3T 5M1</p>
                                        <a href="tel:(519) 720 2575">(519) 720 2575</a>
                                    </div>
                                    <br></br>
                                </div>

                                {/* <div className="sub-department">
                                    <h5><b>Cornwall, Ontario</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p  style={{ marginBottom: '0'}}>1750B McConnell Ave, K6H 5R6</p>
                                        <a href="tel:(613) 930 7350">(613) 930 7350</a>
                                    </div>
                                    <br></br>
                                </div> */}

                                <div className="sub-department">
                                    <h5><b>Montréal, Québec</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p  style={{ marginBottom: '0'}}>3100 Blvd. Cote-Vertu Ouest, Suite 230, H4R 2J8</p>
                                        <a href="tel:(514) 252 5810">(514) 252 5810</a>
                                    </div>
                                    <br></br>
                                </div>

                                <div className="sub-department">
                                    <h5><b>Napanee, Ontario</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p  style={{ marginBottom: '0'}}>824 Highway 41, K0K 2Z0</p>
                                        <a href="tel:(613) 354 6336">(613) 354 6336</a>
                                    </div>
                                    <br></br>
                                </div>

                                <div className="sub-department">
                                    <h5><b>North Bay, Ontario</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p style={{ marginBottom: '0'}}>348 Birch's Road, P1B 8Z4</p>
                                        <a href="tel:(705) 476 4411">(705) 476 4411</a>
                                    </div>
                                    <br></br>
                                </div>

                                <div className="sub-department">
                                    <h5><b>Windsor, Ontario</b></h5>
                                    <div style={{marginTop: '-1rem' }} className="contact">
                                        <p style={{ marginBottom: '0'}}>3315 Devon Drive, N8X 4L5</p>
                                        <a href="tel:(519) 972 3401">(519) 972 3401</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ContactUs