import React from 'react';
import '../styles/AboutUs.css';
import { Breadcrumb } from 'semantic-ui-react';
import Timeline from '../assets/timeline_new.png'



const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/", ariaLabel: "Site Home Link" },
    { key: 'about us', content: 'ABOUT US', ariaLabel: "About Us",active: true },
]

function AboutUs() {

    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>


            <div className="fr-header">About Us</div>

            <div className="aboutus-container">
                <div className="timelineimage">
                    <img src={Timeline} alt="timeline" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '-webkit-fill-available', maxWidth: '1500px' }} />
                </div>

                <div className="aboutus-text" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>

                    <p>Established in 2002 by Ted and Marilyn Daniel, Titanium Transportation Group's origins trace back to a single room where brokered loads marked its modest beginnings.
                        Swiftly evolving, this progress led to the acquisition of larger headquarters and the purchase of its first straight truck within two years.
                        Since then, Titanium has extended its presence to 18 North American locations, with a targeted expansion to 20 by the end of 2024.</p>

                    <p>Titanium is a leading North American transportation company with asset-based trucking operations and logistics brokerages servicing Canada and the United States,
                        with approximately 800 power units, 2,800  trailers and 1100 employees and independent owner operators.
                        Titanium provides truckload, dedicated, and cross-border trucking services, logistics, warehousing and distribution to over 1,000 customers,
                        and also owns over 100,000 sq ft. of warehouse space.</p>

                    <p>Titanium is a recognized purchaser of asset-based trucking companies, having completed thirteen (13) transactions since 2011.
                        Notably, the Company’s most recent purchase of Crane Transport in 2023 marked Titanium’s first U.S. based trucking acquisition.</p>

                    <p>Titanium ranked among top 500 companies in the inaugural Financial Times Americas’ Fastest Growing Companies in 2020.
                        The Company was ranked by Canadian Business as one of Canada's Fastest Growing Companies for eleven (11) consecutive years.
                        For two (2) consecutive years, Titanium has also been ranked one of Canada’s Top Growing Companies by the Globe and Mail’s Report on Business of Canada.
                        Titanium is currently listed on the Toronto Stock Exchange under the symbol “TTNM" and “TTNMF” on the OTCQX.</p>

                </div>
                < br />
                < br />
                < br />
            </div>
        </>
    )
}

export default AboutUs
